<template>
  <b-card>
    <b-overlay
      :show="!leads"
      rounded="sm"
    >
      <div class="pending-leads-table">
        <div style="display:flex;align-items: center; gap:10px; margin-bottom: 25px;justify-content: space-between;">
          <div style="display:flex;gap:10px;">
            <button
              v-b-modal.template-actions
              class="btn btn-sm btn-primary phn-btn adj"
            >
              Actions
            </button>

            <button
              v-b-modal.add-new-lead
              class="btn btn-sm btn-warning border-0 pc-btn adj"
              style="background-color:goldenrod !important;"
              type="button"
            >
              Add Lead
            </button>
            <button
              class="btn btn-sm btn-primary border-0 pc-btn adj"
              style="background-color:fuchsia !important;"
              type="button"
              @click="editFunc"
            >
              Edit Lead
            </button>
            <button
              class="btn btn-sm btn-danger pc-btn adj"
              type="button"
              @click="deleteFunc"
            >
              Delete Selected
            </button>
            <button
              v-b-modal.add-new-source
              class="btn btn-sm btn-secondary pc-btn adj"
              type="button"
            >
              Add Ext. Source
            </button>
            <button
              class="btn btn-sm btn-info border-0 pc-btn adj"
              style="background-color:darkcyan !important;"
              type="button"
              @click="emailFunc"
            >
              Email Selected
            </button>
          </div>
          <div style="display: flex; gap:10px;">
            <button
              v-b-modal.add-new-template
              class="btn btn-sm btn-success pc-btn adj"
              type="button"
            >
              Add Template
            </button>
            <button
              v-b-modal.template-actions-view
              class="btn btn-sm btn-warning pc-btn adj"
              type="button"
            >
              View Templates
            </button>
            <button
              v-b-modal.search-filter-modal
              variant="outline-primary"
              class="btn btn-sm btn-primary border-0 pc-btn adj"
            >
              Filter
            </button>
          </div>
        </div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="leads"
          ref="table"
          :columns="columns"
          :rows="leads"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'selected-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Demo Version -->
            <span v-if="props.column.field === 'platform'">
              <b-badge :variant="demoVersion(props.row.platform)">
                {{ props.row.platform }}
              </b-badge>
            </span>

            <!-- Column: Email -->
            <span v-else-if="props.column.field === 'email'">
              <b-badge
                :id="`tooltip-target-${props.row.id}`"
                :variant="checkRegisterdEmail(props.row.email) ? 'light-success' : 'light-danger'"
              >
                {{ props.row.email }}
              </b-badge>
              <b-tooltip
                :target="`tooltip-target-${props.row.id}`"
                triggers="hover"
                placement="right"
                :variant="checkRegisterdEmail(props.row.email) ? 'success' : 'danger'"
              >
                <b>This email is <span v-if="!checkRegisterdEmail(props.row.email)">not</span> registered</b>
              </b-tooltip>
            </span>

            <!-- Column: Website -->
            <span v-else-if="props.column.field === 'website'">
              <p class="truncate"><a
                v-b-tooltip.hover.focus.v-primary
                :href="props.row.website"
                target="_blank"
                :title="props.row.website"
              >{{ props.row.website }}</a></p>
            </span>

            <!-- Column: Instant Messenger -->
            <a
              v-else-if="props.column.field === 'messaging_tool'"
              class="m-0"
              target="_blank"
              :href="instantMessage(props.row.messaging_tool, props.row.contact)"
            >
              <i
                v-if="props.row.messaging_tool === 'Skype'"
                v-b-tooltip.hover.focus.v-primary
                title="Skype"
                class="bi bi-skype"
              />
              <i
                v-else-if="props.row.messaging_tool === 'Whatsapp'"
                v-b-tooltip.hover.focus.v-primary
                title="Whatsapp"
                class="bi bi-whatsapp"
              />
              <i
                v-else-if="props.row.messaging_tool === 'Telegram'"
                v-b-tooltip.hover.focus.v-primary
                title="Telegram"
                class="bi bi-telegram"
              />
            </a>

            <!-- Column: Presentation -->
            <span v-else-if="props.column.field === 'with_platform_presentation'">
              <feather-icon
                :icon="props.row.has_platform_presentation ? 'CheckIcon' : 'XIcon'"
                class="inline-flex"
                :class="props.row.has_platform_presentation ? 'text-success' : 'text-danger'"
                size="21"
              />
            </span>

            <!-- Column: Notes -->
            <span v-else-if="props.column.field === 'notes'">
              <router-link
                v-b-tooltip.hover.focus.v-primary
                :to="'/leads/' + props.row.id + '/add-note'"
                :class="props.row.notes ? 'bi bi-sticky-fill' : 'bi bi-sticky'"
                :title="props.row.notes"
              />
            </span>

            <!-- Column: IP -->
            <p
              v-else-if="props.column.field === 'ip'"
              class="m-0"
            >
              <a
                :id="`ip-info-${props.index}`"
                href="javascript:void(0)"
              >
                <b-tooltip
                  :target="`ip-info-${props.index}`"
                >
                  IP: {{ props.row.ip }}<br>
                  CC: {{ props.row.cc }}<br>
                  CREATED AT: {{ props.row.created_format }}<br>
                  UPDATED AT: {{ props.row.updated_format }}
                </b-tooltip>
                <feather-icon
                  icon="SearchIcon"
                  class="inline-flex"
                  size="20"
                />
              </a>
            </p>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="markCalled(props.row.id)">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-50"
                    />
                    <span>Mark As Called</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="sendInvite(props.row.id, props.row.email)">
                    <feather-icon
                      icon="MailIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Send Invite</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteLeadUser(props.row.id)">
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25','50','100','150','200']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
    <!-- EDIT LEAD MODAL!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <b-modal
      id="edit-lead"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Modify"
      centered
      title="Edit lead"
      no-close-on-backdrop
      @ok="handleEdit"
    >
      <b-form ref="form">
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Name (recalled in emails)"
            >
              <b-form-input
                id="name"
                v-model="editLead.name"
                label="Name"
                required
                placeholder="Enter name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Company (recalled in emails)"
            >
              <b-form-input
                id="company"
                v-model="editLead.company"
                label="Company"
                required
                placeholder="Enter company"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email (recalled in emails)"
            >
              <b-form-input
                id="email"
                v-model="editLead.email"
                required
                placeholder="Enter email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Contact Full Name"
            >
              <b-form-input
                id="full_name"
                v-model="editLead.contactFullName"
                required
                placeholder="Enter contact full name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Website"
            >
              <b-form-input
                id="website"
                v-model="editLead.website"
                required
                placeholder="Enter website"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Contact Tool"
            >
              <b-form-select
                id="contact_type"
                v-model="editLead.contact_type"
                required
                placeholder="Enter contact tool"
              >
                <option value="1">
                  Whatsapp
                </option>
                <option value="3">
                  Telegram
                </option>
                <option value="2">
                  Skype
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Contact ID"
            >
              <b-form-input
                id="contact"
                v-model="editLead.contact"
                required
                placeholder="Enter contact ID"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Company Description (recalled in emails)"
            >
              <b-form-input
                id="companyDescription"
                v-model="editLead.companyDescription"
                required
                placeholder="Enter company description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Source (recalled in emails)"
            >
              <b-form-select
                id="source"
                v-model="editLead.source"
                required
                placeholder="Enter source"
              >
                <option
                  v-for="option in externalSources"
                  :key="option.id"
                >
                  {{ option }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Template"
            >
              <b-form-select
                id="template"
                v-model="editLead.template"
                required
                placeholder="Enter template"
              >
                <option
                  v-for="option in templates"
                  :key="option.id"
                >
                  {{ option }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Position"
            >
              <b-form-input
                id="position"
                v-model="editLead.position"
                required
                placeholder="Enter position"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Platform Type"
            >
              <b-form-select
                id="platform"
                v-model="editLead.platform"
                required
                placeholder="Enter platform type"
              >
                <option value="Normal">
                  Normal Version
                </option>
                <option value="Casino">
                  Casino Version
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ADD LEAD MODAL!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <b-modal
      id="add-new-lead"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Add"
      centered
      title="Add new lead"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <b-form ref="form">
        <validation-observer ref="rules">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Name (recalled in emails)"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="name"
                    v-model="newLead.name"
                    label="Name"
                    required
                    placeholder="Enter name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small><br>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Company (recalled in emails)"
              >
                <b-form-input
                  id="company"
                  v-model="newLead.company"
                  label="Company"
                  placeholder="Enter company"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Email (recalled in emails)"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="newLead.email"
                    required
                    placeholder="Enter email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small><br>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Contact Full Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contact_full_name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="contact_full_name"
                    v-model="newLead.contactFullName"
                    required
                    placeholder="Enter contact full name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small><br>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Website"
              >
                <b-form-input
                  id="website"
                  v-model="newLead.website"
                  placeholder="Enter website"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Contact Tool"
              >
                <b-form-select
                  id="contact_type"
                  v-model="newLead.contact_type"
                  placeholder="Enter contact tool"
                >
                  <option value="1">
                    Whatsapp
                  </option>
                  <option value="3">
                    Telegram
                  </option>
                  <option value="2">
                    Skype
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Contact ID"
              >
                <b-form-input
                  id="contact"
                  v-model="newLead.contact"
                  placeholder="Enter contact ID"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Company Description (recalled in emails)"
              >
                <b-form-input
                  id="companyDescription"
                  v-model="newLead.companyDescription"
                  placeholder="Enter company description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Source (recalled in emails)"
              >
                <b-form-select
                  id="source"
                  v-model="newLead.source"
                  required
                  placeholder="Enter source"
                >
                  <option
                    v-for="option in externalSources"
                    :key="option.id"
                  >
                    {{ option }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Template"
              >
                <b-form-select
                  id="template"
                  v-model="newLead.template"
                  required
                  placeholder="Enter template"
                >
                  <option
                    v-for="option in templates"
                    :key="option.id"
                  >
                    {{ option }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Position"
              >
                <b-form-input
                  id="position"
                  v-model="newLead.position"
                  placeholder="Enter position"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Platform Type"
              >
                <b-form-select
                  id="platform"
                  v-model="newLead.platform"
                  required
                  placeholder="Enter platform type"
                >
                  <option value="Normal">
                    Normal Version
                  </option>
                  <option value="Casino">
                    Casino Version
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="add-new-source"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Add"
      centered
      title="Add new external source"
      no-close-on-backdrop
      @ok="handleOk2"
    >
      <b-form ref="form">
        <b-row>
          <b-col cols="12">
            <validation-observer ref="rules">
              <b-form-group
                label="New Source"
              >
                <validation-provider
                  #default="{ errors }"
                  name="source"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="source"
                    v-model="newSource"
                    required
                    placeholder="Enter source"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small><hr>
                </validation-provider>
                <b-form-group
                  label="Source to Edit"
                >
                  <b-form-select
                    id="template"
                    v-model="newLead.source"
                    placeholder="Enter template"
                  >
                    <option
                      v-for="source in externalSources"
                      :key="source.id"
                    >
                      {{ source }}
                    </option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="newLead.source"
                  label="Edit Source Name"
                  class="mt-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="source"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="source"
                      v-model="editSource"
                      required
                      placeholder="source name"
                      autocomplete="off"
                    />
                    <small class="text-warning">The name of the source will be changed for all users !</small><br>
                    <small class="text-danger">{{ errors[0] }}</small><br>
                    <button
                      class="btn btn-sm btn-warning"
                      type="button"
                      @click="editSourceName"
                    >
                      Edit
                    </button>
                  </validation-provider>
                </b-form-group>
              </b-form-group>
            </validation-observer>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="add-new-template"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Add new template"
      centered
      title="Add a template"
      no-close-on-backdrop
      @ok="handleOk3"
    >
      <b-form ref="form">
        <b-form-group
          lable="Title"
          label-for="title"
          invalid-feedback="Title and Body are required"
        >
          <validation-provider
            #default="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="template.title"
              placeholder="Enter template title"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small><br>
          </validation-provider>
        </b-form-group>
        <b-form-group
          invalid-feedback="Body is required"
          label-for="body"
          label="Subject"
        >
          <validation-provider
            #default="{ errors }"
            name="subject"
            rules="required"
          >
            <b-form-input
              id="subject"
              v-model="template.subject"
              placeholder="Enter a subject"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small><br>
          </validation-provider>
        </b-form-group>
        <b-form-group
          invalid-feedback="Body is required"
          label-for="body"
          label="Body"
        >
          <validation-provider
            #default="{ errors }"
            name="subject"
            rules="required"
          >
            <b-form-textarea
              id="body"
              v-model="template.body"
              style="height: 250px"
              placeholder="Enter template body"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small><br>
          </validation-provider>
        </b-form-group>
        <b-form-group
          invalid-feedback="Footer is required"
          label-for="body"
          label="Footer"
        >
          <validation-provider
            #default="{ errors }"
            name="subject"
            rules="required"
          >
            <b-form-textarea
              id="footer"
              v-model="template.footer"
              style="height: 100px"
              placeholder="Enter template footer"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small><br>
          </validation-provider>
        </b-form-group>
        <small style="margin-bottom: 10px;">Dynamic Variables : {source} | {company} | {name} | {email} </small>
        <br>
        <small style="margin-bottom: 10px;">For links, make sure they start with http:// or https://</small>
      </b-form>
    </b-modal>
    <!-- TEMPLATE EDIT,VIEW MODAL !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
    <b-modal
      id="template-actions-view"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Close"
      centered
      title="View Template"
      no-close-on-backdrop
      @ok="handleClose"
    >
      <b-form ref="form">
        <b-form-group
          lable="Title"
          label-for="title"
          invalid-feedback="Title and Body are required"
        >
          <b-form-select
            id="title"
            v-model="selectedTemplate"
            placeholder="Enter template title"
            required
            @change="updateBody"
          >
            <option
              v-for="option in templatesInfo"
              :key="option.id"
              :value="option.body"
            >
              {{ option.title }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          invalid-feedback="Body is required"
          label-for="body"
          label="Subject"
        >
          <b-form-input
            id="subject"
            v-model="selectedTemplateSubject"
            placeholder="Enter a subject"
            required
          />
        </b-form-group>
        <b-form-group
          invalid-feedback="Body is required"
          label-for="body"
          label="Body"
        >
          <b-form-textarea
            id="body"
            v-model="selectedTemplateBody"
            style="height: 250px"
            placeholder="Enter template body"
            required
          />
        </b-form-group>
        <b-form-group
          invalid-feedback="Footer is required"
          label-for="footer"
          label="Footer"
        >
          <b-form-textarea
            id="body"
            v-model="selectedTemplateFooter"
            style="height: 100px"
            placeholder="Enter template footer"
            required
          />
        </b-form-group>
        <b-form-group>
          <small style="margin-bottom: 10px;">Dynamic Variables : {source} | {company} | {name} | {email} </small>
          <br>
          <small style="margin-bottom: 10px;">For links, make sure they start with http:// or https://</small>
          <div style="display:flex;justify-content: flex-start; gap:3%;margin-top: 25px;">
            <button
              class="btn btn-sm btn-warning"
              type="button"
              @click="updateTemplate()"
            >
              Update
            </button>
            <button
              class="btn btn-sm btn-danger"
              type="button"
              @click="deleteTemplate()"
            >
              Delete
            </button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="template-actions"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Close"
      centered
      title="Actions"
      no-close-on-backdrop
      @ok="handleClose"
    >

      <b-form ref="form">
        <b-form-group
          lable="Title"
          label-for="title"
          invalid-feedback="Title and Body are required"
        >
          <div style="display:flex; justify-content: space-around;">
            <button
              v-b-modal.add-new-lead
              class="btn btn-sm btn-warning border-0 adj"
              style="background-color:goldenrod !important;"
              type="button"
            >
              Add Lead
            </button>
            <button
              class="btn btn-sm btn-primary border-0 adj"
              style="background-color:fuchsia !important;"
              type="button"
              @click="editFunc"
            >
              Edit Lead
            </button>
            <button
              class="btn btn-sm btn-danger adj"
              type="button"
              @click="deleteFunc"
            >
              Delete Selected
            </button>
          </div>
          <div style="display:flex; justify-content: space-around;margin-top: 25px;">
            <button
              v-b-modal.add-new-source
              class="btn btn-sm btn-secondary adj"
              type="button"
            >
              Add Ext. Source
            </button>
            <button
              class="btn btn-sm btn-info border-0 adj"
              style="background-color:darkcyan !important;"
              type="button"
              @click="emailFunc"
            >
              Email Selected
            </button>
          </div>
          <div style="display:flex;justify-content: space-around;margin-top: 50px;">
            <button
              v-b-modal.add-new-template
              class="btn btn-sm btn-success adj"
              type="button"
            >
              Add Template
            </button>
            <button
              v-b-modal.template-actions-view
              class="btn btn-sm btn-warning adj"
              type="button"
            >
              View Templates
            </button>
            <button
              v-b-modal.search-filter-modal
              variant="outline-primary"
              type="button"
              class="btn btn-sm btn-primary border-0 adj"
              style="background-color:blueviolet !important;"
            >
              Filter
            </button>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- FILTER!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
    <b-modal
      id="search-filter-modal"
      ok-title="Apply"
      cancel-title="Reset"
      centered
      title="Filter"
      @ok="handleApply"
      @cancel="handleReset"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Creation Date:"
              label-for="referral-type"
            >
              <date-range-picker
                v-model="filter.creationDates"
                style="width: 215px;"
              >
                <template
                  style="min-width: 350px;"
                >{{ filter.creationDates.startDate | date }} - {{ filter.creationDates.endDate | date }}</template>
                <!--    footer slot-->
                <div
                  slot="footer"
                  slot-scope="data"
                  class="slot"
                >
                  <div class="custom-search d-flex justify-content-end">
                    <span>Date Range:</span> <span>{{ data.rangeText }}</span>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="data.clickApply"
                    >
                      Ok
                    </b-button>
                  </div>
                </div>
              </date-range-picker>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Platform Type:"
              label-for="referral-type"
            >
              <b-form-select
                id="platform-type"
                v-model="filter.platformType"
                :options="[{ value: 'Casino', text: 'Casino Version' },{ value: 'Normal', text: 'Normal Version' }]"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Sorce:"
              label-for="referral-type"
            >
              <b-form-select
                id="source-select"
                v-model="filter.source"
                :options="externalSources"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BBadge,
  BTooltip,
  VBTooltip,
  BOverlay,
  BForm,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@/libs/axios'
import store from '@/store/index'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BOverlay,
    BForm,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    DateRangePicker,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat('en-US').format(date)
    },
  },
  data() {
    const startDate = null
    const endDate = null
    return {
      pageLength: 50,
      linkExample: '<code><a style="text-decoration:none;" href="(link here)">(text to click)</a></code>',
      dir: false,
      template: {
        title: '',
        body: '',
        subject: '',
        footer: '',
        ext_tag: true,
      },
      templates: [],
      templatesInfo: [],
      selectedTemplate: '',
      selectedTemplateBody: '',
      selectedTemplateSubject: '',
      selectedTemplateFooter: '',
      ids: '',
      newLead: {
        name: '',
        company: '',
        email: '',
        contactFullName: '',
        website: '',
        contact_type: '1',
        contact: '',
        companyDescription: '',
        position: '',
        platform: 'Normal',
        source: '',
        template: '',
      },
      editLead: {
        id: '',
        name: '',
        company: '',
        email: '',
        contactFullName: '',
        website: '',
        contact_type: '1',
        contact: '',
        companyDescription: '',
        position: '',
        platform: '',
        source: '',
        template: '',
      },
      newSource: null,
      editSource: null,
      externalSources: [],
      filter: {
        platformType: null,
        source: null,
        creationDates: {
          startDate,
          endDate,
        },
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
        },
        {
          label: 'Creation Date',
          field: 'created_at',
          type: 'dateTime',
          formatFn: value => {
            const date = new Date(value)
            const year = date.getFullYear()
            const month = (`0${date.getMonth() + 1}`).slice(-2)
            const day = (`0${date.getDate()}`).slice(-2)
            return `${year}-${month}-${day}`
          },
          filterOptions: {
            enabled: false,
            filterValue: '',
            filterFn: this.filterCreationDate,
          },
          sortable: true,
        },
        {
          label: 'Contacted',
          field: 'contacted',
          sortable: false,
        },
        {
          label: 'Contacted Date',
          field: 'contacted_date',
          sortable: false,
          formatFn: value => {
            if (value === null) {
              return 'N/A'
            }
            const date = new Date(value)
            const year = date.getFullYear()
            const month = (`0${date.getMonth() + 1}`).slice(-2)
            const day = (`0${date.getDate()}`).slice(-2)
            return `${year}-${month}-${day}`
          },
        },
        {
          label: 'Last Reminder',
          field: 'reminder',
          sortable: false,
          formatFn: value => {
            if (value === null) {
              return 'Not Sent'
            }
            const date = new Date(value)
            const year = date.getFullYear()
            const month = (`0${date.getMonth() + 1}`).slice(-2)
            const day = (`0${date.getDate()}`).slice(-2)
            return `${year}-${month}-${day}`
          },
        },
        {
          label: 'Reminders Sent',
          field: 'nr_of_emails',
          sortable: false,
          formatFn: value => {
            if (value === null || value === 1) {
              return 'Not Sent'
            }
            if (value >= 2) {
              return value - 1
            }
            return ''
          },
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Source',
          field: 'source',
          filterOptions: {
            enabled: false,
            filterValue: '',
            filterFn: this.filterSource,
          },
        },
        {
          label: 'Platform Type',
          field: 'platform',
          filterOptions: {
            enabled: false,
            filterValue: '',
            filterFn: this.filterPlatformType,
          },
        },
        {
          label: 'Company',
          field: 'company',
        },
        {
          label: 'Position',
          field: 'position',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Email Sub.',
          field: 'sub_to_emails',
          sortable: false,
          formatFn: value => {
            if (value === 1) {
              return 'Yes'
            }
            return 'No'
          },
        },
        {
          label: 'Template',
          field: 'template',
          sortable: false,
        },
        {
          label: 'Instant Messenger',
          field: 'messaging_tool',
          sortable: false,
        },
        {
          label: 'IM Address',
          field: 'contact',
          sortable: false,
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
        },
        {
          label: 'Website',
          field: 'website',
          sortable: false,
        },
        {
          label: 'Contact Full Name',
          field: 'contact_full_name',
          sortable: false,
        },
        {
          label: 'Company Description',
          field: 'companyDescription',
          width: '200px',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      registeredUsersEmail: [],
      // selectedRowsCount: 0,
    }
  },
  computed: {
    demoVersion() {
      const presentationColor = {
        Casino: 'light-warning',
        Normal: 'light-secondary',
      }
      return presentation => presentationColor[presentation]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    leads() {
      return this.$store.state.users.externalLeads
    },
  },
  created() {
    this.fetchExternalLeads()
  },
  mounted() {
    // setTimeout(() => {
    //   this.$watch(
    //     () => this.$refs.table.selectedRows,
    //     newValue => {
    //       this.selectedRowsCount = newValue.map(row => row.id).length || 0
    //     },
    //   )
    // }, 1000)
  },
  methods: {
    checkRegisterdEmail(email) {
      return this.registeredUsersEmail.includes(email)
    },
    filterPlatformType(data, status) {
      if (typeof data === 'undefined') return // eslint-disable-line
      return (data === status) // eslint-disable-line
    },
    filterSource(data, status) {
      if (typeof data === 'undefined') return // eslint-disable-line
      return (data === status) // eslint-disable-line
    },
    handleReset(bvModalEvt) {
      if (bvModalEvt) bvModalEvt.preventDefault()

      this.filter.creationDates.startDate = null
      this.filter.creationDates.endDate = null
      this.filter.platformType = null
      this.filter.source = null

      this.$set(this.columns[1].filterOptions, 'filterValue', '')
      this.$set(this.columns[10].filterOptions, 'filterValue', '')
      this.$set(this.columns[7].filterOptions, 'filterValue', '')

      if (bvModalEvt) this.removeExtraTableHeader()
    },

    removeExtraTableHeader() {
      const refreshId = setInterval(() => {
        if (this.$refs.table.$refs.table.tHead.rows.length > 1) {
          this.$refs.table.$refs.table.tHead.rows[1].hidden = true
          clearInterval(refreshId)
        }
      }, 100)
    },
    filterCreationDate(data, CreationDate) {
      return this.filterDate(data, CreationDate)// eslint-disable-line
    },
    filterDate(data, CreationDate) {
      if (typeof data === 'undefined') return // eslint-disable-line
      const paramDate = Date.parse(this.filterFormatDate(data))
      const startDate = Date.parse(this.filterFormatDate(CreationDate.startDate))
      const endDate = Date.parse(this.filterFormatDate(CreationDate.endDate))
      return (paramDate >= startDate && paramDate <= endDate)// eslint-disable-line
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
    handleApply() {
      // Creation date filter
      if (this.filter.creationDates.startDate !== null) {
        this.$set(this.columns[1].filterOptions, 'enabled', true)
        this.$set(this.columns[1].filterOptions, 'filterValue', this.filter.creationDates)
        this.columns[1].filterOptions.filterFn()
      }
      // Platform Type filter
      if (this.filter.platformType !== null) {
        this.$set(this.columns[10].filterOptions, 'enabled', true)
        this.$set(this.columns[10].filterOptions, 'filterValue', this.filter.platformType)
        this.columns[10].filterOptions.filterFn()
      }
      // Source filter
      if (this.filter.source !== null) {
        this.$set(this.columns[7].filterOptions, 'enabled', true)
        this.$set(this.columns[7].filterOptions, 'filterValue', this.filter.source)
        this.columns[7].filterOptions.filterFn()
      }
    },
    handleEdit() {
      // ask if he wants to edit the selected row
      this.$bvModal.msgBoxConfirm('Are you sure you want to edit this lead?', {
        title: 'Edit Lead',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (value) {
            const { id } = this.$refs.table.selectedRows[0]
            const payload = {
              id,
              name: this.editLead.name,
              company: this.editLead.company,
              email: this.editLead.email,
              website: this.editLead.website,
              contact_full_name: this.editLead.contactFullName,
              contact_type: this.editLead.contact_type,
              contact: this.editLead.contact,
              companyDescription: this.editLead.companyDescription,
              position: this.editLead.position,
              platform: this.editLead.platform,
              source: this.editLead.source,
              template: this.editLead.template,
            }
            const res = await axios.patch('api/admin/update_external_lead', payload)
            if (res.status === 200) {
              this.$bvToast.toast('Lead edited successfully.', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
              this.fetchExternalLeads()
              this.$refs.modal.hide()
            } else {
              this.$bvToast.toast('An error occurred while editing the lead !', {
                title: 'Error',
                variant: 'danger',
                solid: true,
              })
            }
          }
        })
        .catch(err => {
          // notify of error
          console.log(err)
          this.$bvToast.toast('An error occurred while editing the lead !', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
    },
    async editFunc() {
      const ids = this.$refs.table.selectedRows.map(row => row.id)
      if (ids.length !== 1) {
        this.$bvToast.toast('Please select one row to edit !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        // hide the edit modal
        this.$refs.modal.hide()
        return
      }
      this.$bvModal.show('edit-lead')
      // take the id of the selected row, and prefill the edit form with the data of the selected row
      // eslint-disable-next-line no-shadow
      const lead = await this.leads.find(lead => lead.id === ids[0])
      this.editLead.name = lead.name
      this.editLead.company = lead.company
      this.editLead.email = lead.email
      this.editLead.contactFullName = lead.contact_full_name
      this.editLead.website = lead.website
      this.editLead.contact_type = lead.contact_type
      this.editLead.contact = lead.contact
      this.editLead.companyDescription = lead.companyDescription
      this.editLead.position = lead.position
      this.editLead.platform = lead.platform
      this.editLead.source = lead.source
      this.editLead.template = lead.template
      this.editLead.messaging_tool = lead.messaging_tool
    },
    deleteTemplate() {
      // find in the templates array the template with the body that matches the selectedTemplate
      const templateToDelete = this.templatesInfo.find(template => template.body === this.selectedTemplate)
      // grab his id and delete it
      const payload = {
        id: templateToDelete.id,
        body: templateToDelete ? templateToDelete.body : '',
      }
      // ask for confirmation
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this template?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          axios.post('/api/admin/templates', payload).then(res => {
            if (!res) {
              this.$bvToast.toast('Something went wrong !', { title: 'Error', variant: 'danger', solid: true })
              this.$refs.modal.hide()
              return
            }
            if (res.status === 200) {
              this.$refs.modal.hide()
              this.fetchTemplates()
              // filter the data array te remove the deleted template
              const deletedTemplate = this.templates.find(template => template.id === payload.id)
              this.templates = this.templates.filter(template => template !== deletedTemplate)
              this.$bvToast.toast('Template deleted successfully.', {
                title: 'Success',
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 3000,
              })
            } else {
              this.$bvToast.toast('Something went wrong !', {
                title: 'Error',
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 3000,
              })
            }
          })
        }
      })
    },
    editSourceName() {
      if (!this.editSource || this.editSource === '') {
        this.$bvToast.toast('Please enter a source name !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      if (this.externalSources.includes(this.editSource)) {
        this.$bvToast.toast('Source already exists !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      this.$bvModal.msgBoxConfirm('Are you sure you want to edit the name of this source?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const payload = {
            oldName: this.newLead.source,
            newName: this.editSource,
          }
          axios.patch('/api/admin/edit_external_source', payload).then(res => {
            if (!res) {
              this.$bvToast.toast('Something went wrong !', { title: 'Error', variant: 'danger', solid: true })
              this.$refs.modal.hide()
              return
            }
            if (res.status === 200) {
              this.$refs.modal.hide()
              this.getExternalSources()
              this.fetchExternalLeads()
              this.$bvToast.toast('Source edited successfully.', {
                title: 'Success',
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 3000,
              })
              this.newLead.source = null
              this.editSource = null
              this.newSource = null
            } else {
              this.$bvToast.toast('Something went wrong !', {
                title: 'Error',
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 3000,
              })
            }
          })
        }
      })
    },
    updateTemplate() {
      const templateToUpdate = this.templatesInfo.find(template => template.body === this.selectedTemplate)
      const payload = {
        id: templateToUpdate.id,
        title: templateToUpdate.title,
        body: this.selectedTemplateBody,
        subject: this.selectedTemplateSubject,
        footer: this.selectedTemplateFooter,
      }
      this.$bvModal.msgBoxConfirm('Are you sure you want to update this template?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          axios.patch('/api/admin/templates', payload).then(res => {
            if (!res) {
              this.$bvToast.toast('Something went wrong !', { title: 'Error', variant: 'danger', solid: true })
              this.$refs.modal.hide()
              return
            }
            if (res.status === 200) {
              this.$refs.modal.hide()
              this.fetchTemplates()
              this.$bvToast.toast('Template updated successfully.', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
              this.fetchTemplates()
            } else {
              this.$refs.modal.hide()
              this.$bvToast.toast('Something went wrong !', {
                title: 'Error',
                variant: 'danger',
                solid: true,
              })
            }
          })
        }
      })
    },
    updateBody() {
      this.selectedTemplateBody = this.selectedTemplate
      this.selectedTemplateSubject = this.templatesInfo.find(template => template.body === this.selectedTemplate).subject
      this.selectedTemplateFooter = this.templatesInfo.find(template => template.body === this.selectedTemplate).footer
    },
    handleClose() {
      this.selectedTemplateBody = this.selectedTemplate
      this.selectedTemplateSubject = this.templatesInfo.find(template => template.body === this.selectedTemplate).subject
      this.selectedTemplateFooter = this.templatesInfo.find(template => template.body === this.selectedTemplate).footer
    },
    async fetchTemplates() {
      await axios.get('/api/admin/templates').then(res => {
        // eslint-disable-next-line no-param-reassign
        res.data.templates = res.data.templates.filter(template => template.ext_tag === 'yes')
        this.templates = res.data.templates.map(template => template.title)
        this.templatesInfo = res.data.templates
        this.selectedTemplate = this.templatesInfo[0].body
        this.selectedTemplateBody = this.templatesInfo[0].body
        this.selectedTemplateSubject = this.templatesInfo[0].subject
        this.selectedTemplateFooter = this.templatesInfo[0].footer
      })
    },
    handleSubmit() {
      const payload = {
        title: this.template.title,
        body: this.template.body,
        subject: this.template.subject,
        ext_tag: true,
        footer: this.template.footer,
      }

      if (!payload.title || !payload.body || !payload.subject) {
        this.notify({
          text: 'Please review the completion of the form !',
          variant: 'danger',
        })
        return
      }

      this.$nextTick(() => {
        axios.post('/api/admin/templates_add', payload).then(res => {
          if (!res) {
            this.$bvToast.toast('Something went wrong !', { title: 'Error', variant: 'danger', solid: true })
            this.$refs.modal.hide()
            return
          }
          if (res.status === 200) {
            this.$refs.modal.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Template created successfully.',
                variant: 'success',
              },
            })
            this.fetchTemplates()
          } else {
            this.$refs.modal.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Something went wrong !',
                variant: 'danger',
              },
            })
          }
        })
      })
    },
    handleOk3(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async getExternalSources() {
      const res = await axios.get('api/admin/get_external_sources')
      if (res.status === 200) {
        this.externalSources = res.data.sources.map(source => source.source)
      }
    },
    async addSource() {
      if (!this.newSource || this.newSource?.length < 3) {
        this.$bvToast.toast('Source name must be at least 3 characters long !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }

      // check if source already exists
      if (this.externalSources.includes(this.newSource)) {
        this.$bvToast.toast('Source already exists !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const payload = {
        source: this.newSource,
      }
      const res = await axios.post('api/admin/add_external_source', payload)
      if (res.status === 200) {
        this.$refs.modal.hide()
        this.$bvToast.toast('Source added successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.fetchExternalLeads()
        this.getExternalSources()
        this.newLead.source = null
        this.editSource = null
        this.newSource = null
      } else {
        this.$bvToast.toast('Something went wrong !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    deleteFunc() {
      const ids = this.$refs.table.selectedRows.map(row => row.id)
      if (ids.length <= 0) {
        this.$bvToast.toast('Please select at least one row !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const payload = {
        ids,
      }
      // ask if user is sure to delete selected rows
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete selected rows?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          // eslint-disable-next-line no-console
          if (value) {
            axios.post('api/admin/delete_external_lead', payload)
              .then(res => {
                if (res.status === 200) {
                  this.$bvToast.toast('Leads deleted successfully.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                  })
                  this.fetchExternalLeads()
                } else {
                  this.$bvToast.toast('Something went wrong !', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  })
                }
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    emailFunc() {
      const ids = this.$refs.table.selectedRows.map(row => row.id)
      if (ids.length <= 0) {
        this.$bvToast.toast('Please select at least one row !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const payload = {
        ids,
      }
      // ask if user is sure to delete selected rows
      this.$bvModal.msgBoxConfirm('Are you sure you want to email selected rows?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          // eslint-disable-next-line no-console
          if (value) {
            axios.post('api/admin/email_external_leads', payload)
              .then(res => {
                if (res.status === 200) {
                  this.$bvToast.toast('Leads emailed successfully.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                  })
                  this.$refs.table.unselectAllInternal()
                } else {
                  this.$bvToast.toast('Something went wrong !', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                  })
                }
              })
              .catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
              })
          }
        })
        .catch(err => {
          // An error occurred
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.addLead()
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.addSource()
    },
    async addLead() {
      const payload = {
        name: this.newLead.name,
        company: this.newLead.company,
        email: this.newLead.email,
        contact_full_name: this.newLead.contactFullName,
        website: this.newLead.website,
        contact_type: this.newLead.contact_type,
        contact: this.newLead.contact,
        companyDescription: this.newLead.companyDescription,
        position: this.newLead.position,
        platform: this.newLead.platform,
        source: this.newLead.source,
        template: this.newLead.template,
      }
      const validator = []

      if (!payload.name || payload.name?.length < 3) validator.push('Name field is empty or less than 3 characters !')
      if (!payload.contact_full_name || payload.contact_full_name?.length < 3) validator.push('Contact Full Name field is empty or less than 3 characters !')
      if (!payload.email) validator.push('Email field is required !')
      if (!payload.source) validator.push('Source field is required !')
      if (!payload.template) validator.push('Template field is required !')

      if (validator.length > 0) {
        const errorMSG = validator.reduce((text, e) => text += (e + "<br>"), ''); // eslint-disable-line
        this.notify({
          text: `${errorMSG}`,
          variant: 'danger',
        })
        return
      }

      try {
        const res = await axios.post('api/admin/add_external_lead', payload)
        if (res.status === 200) {
          this.$refs.modal.hide()
          this.$bvToast.toast('Lead added successfully.', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.fetchExternalLeads()
          this.newLead.name = ''
          this.newLead.company = ''
          this.newLead.email = ''
          this.newLead.contactFullName = ''
          this.newLead.website = ''
          this.newLead.contact = ''
          this.newLead.companyDescription = ''
          this.newLead.position = ''
        }
      } catch (error) {
        this.$bvToast.toast('Something went wrong !', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    instantMessage(message_tool, contact) {
      const text = 'Hello, this is Florin from TrafficManager.com , the affiliate tracking platform. I saw you have filled out the form for our tracking platform online demo and I wanted to check and see if I can help you with anything or you have any questions about the product.'
      if (message_tool === 'Whatsapp') {
        return `https://api.whatsapp.com/send/?phone=${contact}&text=${text}&type=phone_number&app_absent=0`
      }
      if (message_tool === 'Telegram') {
        // remove from contact the @
        const contact_fix = contact.replace('@', '')
        return `https://telegram.me/${contact_fix}`
      }
      if (message_tool === 'Skype') {
        return `skype:${contact}?chat`
      }
      return undefined
    },
    fetchExternalLeads() {
      this.$store.dispatch('users/fetchAllUsers').then(user => {
        this.registeredUsersEmail = user.map(e => e.email)
      })
      this.$store.dispatch('users/fetchExternalLeads')
      this.getExternalSources()
      this.fetchTemplates()
    },
    markCalled(id) {
      this.confirmationAlert('Would you like to move this user to the called list?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/updateLeadStatus', { id, status: 3 })
              .then(response => {
                this.$store.commit('users/REMOVE_CALLBACK_LEADS', id)
                this.$store.commit('users/INSERT_CALLED_LEADS', response)
                this.notify({
                  text: 'User has been moved to the called list!',
                  variant: 'success',
                })
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    sendInvite(id, email) {
      this.confirmationAlert(`Would you like to send an email invite to ${email}?`)
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/sendEmailInvite', id)
              .then(() => {
                this.notify({
                  text: `Email invite has been sent to ${email}`,
                  variant: 'success',
                })
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    deleteLeadUser(id) {
      this.confirmationAlert('Would you like to delete this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/deleteLeadUser', id)
              .then(() => {
                this.fetchCallbackLeads()
                this.notify({
                  text: 'User has been deleted!',
                  variant: 'success',
                })
                this.$store.commit('users/REMOVE_CALLBACK_LEADS', id)
              })
              .catch(error => { console.log(error) })
          }
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.calendars {
  color: black;
}

//phone adjustment
@media (min-width: 767px) {
  .phn-btn {
    display: none;
  }
  .pc-btn {
    display: block;
  }
  .adj {
  width: 140px;
  height: 35px;
}
}

@media (max-width: 767px) {
  .pc-btn {
    display: none;
  }
  .adj {
  width: 100px;
  height: 40px;
}
}

.pending-leads-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  .truncate {
    width: 275px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
    margin: auto;
  }

  .bi {
    font-size: 20px;

    &.bi-skype {
      color: #00aff0;
    }

    &.bi-whatsapp {
      color: #44eb62;
    }

    &.bi-telegram {
      color: #3490ee;
    }
  }
}
.mx-datepicker {
  width: 100% !important;
}

.selected-class {
  color: darkgoldenrod;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 55rem !important;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.slot {
  padding: 0.5rem;
  justify-content: space-between;
}
.slot span{
  margin-right:5px; margin-top:5px;
  font-size: 11px;
  font-weight: bold;
}

.modal .modal-header .close{
  margin: 2px
}
.filter-th{
  display: none;
}
.vgt-global-search{
  display: none;
}
// .vgt-selection-info-row{
//   margin-top: 50px !important
// }
.zipProgress{
  bottom: 0px !important;
  position: fixed;
  width: 80%;
  margin-left: -20px;
}
</style>
